
import './App.css'
// import Swap from './components/swap/swap'
// import Header from './components/header/header';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
// import liquidity from './components/liquidity/liquidity'
import {Outlet} from 'react-router-dom'
import ResponsiveAppBar from './components/header/navbar';
import Bootstrapbar from './components/header/bootstrapnavbar';
import { responsiveFontSizes } from '@mui/material';
import { FormatAlignLeft } from '@mui/icons-material';
import { useTranslation, Trans } from 'react-i18next';
import './il8n'

function App() {

  return (

    <div className="App">      
      <Bootstrapbar/>
      {/* <ResponsiveAppBar/> */}
      <header className="App-header">
        {/* <Header/> */}
      </header>
      <div className='App-header'>
      <Outlet/>
      </div>
      
      <footer className="App-header" style={{padding:'2em'}}>
        
        ChurroSwap 2024</footer>


    </div>
  );
}

export default App;
