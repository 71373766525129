import React from "react";
import game1 from '../../logos/gamebackground.png'
import nftimg from '../../logos/nftpageimg-01.jpg'
import { useTranslation } from 'react-i18next';

import { Link } from "react-router-dom";
import logochurros from '../../logos/churritoslogo-04.svg'




const NftPage = () => {

  const { t } = useTranslation();

  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

    return (


<Link to={''} style={{textDecoration:'none'}}>
    <img src={logochurros}></img>
{/* <h3 style={{color:'rgb(51, 20, 15)'}}><strong>Los Churritos</strong></h3> */}
<p style={{color:'rgb(51, 20, 15)'}}>{t("Usar con juegos en ChurroSwap. Vienen Pronto!")}</p>
      <div class="nftimg-container">
            {/* <UnityGame/> */}

            <img src={nftimg} alt="clicker game" ></img>

      </div>

      </Link>

    );
  };

  export default NftPage;

