import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import ErrorPage from './routes/error-page';
import Contact from './routes/contact';
import { Outlet  } from "react-router-dom";
import Liquidity from './components/liquidity/liquidity'
import Swap from './components/swap/swap'
import {store,persistor} from './app/store'
import  {Provider} from 'react-redux'
import GamePage from './components/unity components/unitygame';
import { Course } from './components/Learning/course';
import TestPage from './components/testpage'
import  {Home}  from './components/Home Page/home'
import ChurroClicker from './components/unity components/churroclicker';
import { FormSubmission } from './components/Home Page/formsubmit';
import { PersistGate } from 'redux-persist/integration/react'
import { Docs } from './components/whitepaper/docsPage';
import NftPage from './components/NFT/NFTMainPage'
import Pdfs from './components/whitepaper/pdfs';
import './il8n'

const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
    errorElement:<ErrorPage/>,

    children: [
      {
        path: "liquidity",
        element: <Liquidity/>,
      },
      {
        path: "/intercambio",
        element: <Swap />,
      },
      {
        path: "juegos",
        element: <GamePage/>,
      },
      {
        path: "aprender",
        element: <Course/>,
      },
      {
        path: "testpage",
        element: <TestPage/>,
      },
      {
        path: "/",
        element: <Home/>,
      },
      {
        path: "juegos/churroclicker",
        element: <ChurroClicker/>,
      },
      {
        path: "/sometercorreo",
        element: <FormSubmission/>,
      },
      {
        path: "/nft",
        element: <NftPage/>,
      },
      {
        path: "/documentation",
        element: <Docs/>,
      },
    ]

  },

]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <React.Suspense fallback="loading">
    <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
    <RouterProvider router={router} />
    </PersistGate>
    </Provider>
    </React.Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
