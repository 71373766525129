import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Trans, useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
// import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export default function AccordionExpandIcon() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <div>
      <div>
        <h3 style={{fontWeight:'bold'}}>
          {t("Aprende y gana cripto: Disponible Muy Pronto!")}
        </h3>
      </div>
      <Accordion style={{backgroundColor:'#f9b435'}}>
        <AccordionSummary
        //   expandIcon={<ArrowDownwardIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography><strong>Leccion #1: Que es cripto?</strong></Typography>
        </AccordionSummary>
        <AccordionDetails>
        {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/taalFg_lKnc?si=DxLzwRvaKo8_FpMu" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
        <div class="video-container">
        <div class="video-placeholder"></div>
        </div>
          <Typography>
            <strong>En esta leccion aprenderemos:</strong>
            <br></br>
            <li>Como las monedas digitales son diferentes a las monedas tradicionales fisicas</li>
            <li>Lo basico de la tecnologia "blockchain"</li>
            <li>La importancia de bitcoin</li>
            <li>Ethereum y contratos "smart"</li>
            <li>Monedas digitales alternativas</li>
          </Typography>
        </AccordionDetails>
      </Accordion >
      <Accordion style={{backgroundColor:'#f9b435'}}>
        <AccordionSummary
        //   expandIcon={<ArrowDropDownIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography><strong>Leccion #2</strong></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>Pronto!</p>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}